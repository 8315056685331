header {
	height: 60px;
	line-height: 60px;
	width: 100vw;
	text-align: center;
}

.nav-bar {
	width: 100%;
	height: 40px;
	text-align: center;
	margin-bottom: 20px;
	overflow: hidden;
	white-space: nowrap;
}

.nav-bar button {
	background: #F0EB8D;
	color: black;
	border: none;
	height: 40px;
	
	font-weight: bolder;
	font-size: 1.5vw;
	
	cursor: pointer;
	margin: 0;
	
	display: inline-block;
	transition: all ease .1s;
}

/* odd are button, even is separator */

.nav-bar button:nth-child(odd) {
	width: 39vw;
}

.nav-bar button:nth-child(even) {
	width: 18vw;
	margin: 0 2vw;
	cursor: default;
	opacity: .65;
}

.nav-bar:hover > button:nth-child(odd) {
	width: calc(39vw + 2vw);
}

.nav-bar:hover > button:not(:hover):nth-child(odd) {
	width: calc(39vw - 1vw);
}

.nav-bar:hover > button:nth-child(even) {
	width: calc(18vw - 1vw);
}



@media screen and (max-width: 700px) {
	.nav-bar button {
		font-size: 13px;
	}
}

@media screen and (min-width: 1100px) {
	.nav-bar button {
		font-size: 17px;
	}
}

.footer {
	margin: 20px 40px;
	transform: translateY(-20px);
	opacity: 0;
	animation: content-pop-up .5s forwards;
}

@keyframes content-pop-up {
	from {
		transform: translateY(-20px);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
}

