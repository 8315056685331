.introduction {
	transform: translateY(-20px);
	animation: content-pop-up .5s forwards;
}

.splash {
	background: url(introduction-splash.png) no-repeat;
	height: 300px;
	width: 100%;
	background-position: 50% bottom;
	position: relative;
}

.splash-info {
	position: absolute;
	bottom: 0;
	margin: 0 0 40px 40px;
	color: #F0EB8D;
}

.splash-info .first-name, .splash-info .last-name {
	display: block;
	font-size: 5vw;
}

.splash-info .last-name {
	margin-bottom: 25px;
}

.splash-info .city {
	font-weight: bold;
}

@media screen and (min-width: 1050px) {
	.splash-info .first-name, .splash-info .last-name {
		font-size: 52px;
	}
}

@media screen and (max-width: 800px) {
	.splash-info .first-name, .splash-info .last-name {
		font-size: 40px;
	}
}

.quote {
	margin: 20px 40px;
	max-width: 50%;
}

@media screen and (max-width: 650px) {
	.quote {
		max-width: unset;
	}
}

.cv-download-btn {
	background: #F0EB8D;
	font-weight: bolder;
	font-size: 16px;
	border: none;
	margin: auto 40px;
	padding: 10px 60px 10px 20px;
	cursor: pointer;
	transition: padding ease .2s;
}

.cv-download-btn:hover, .cv-download-btn:active {
	padding-right: 90px;
	padding-left: 10px;
}

.details-view {
	margin: 20px 40px;
}

.video-cv-cont, .about-me {
	width: calc(50% - 20px);
	margin: 0 10px;
	display: inline-block;
	vertical-align: top;
}

.about-me {
	margin-right: 0;
}

.video-cv-cont {
	background: #F0EB8D;
	margin-left: 0;
	padding-bottom: 20px;
}

.video-cv-cont span {
	display: block;
	margin: 10px;
}

.video-cv-cont video {
	width: 100%;
	max-height: 500px;
	background: black;
}

@media screen and (max-width: 500px) {
	.video-cv-cont, .about-me {
		width: 100%;
	}
	
	.about-me {
		margin-top: 20px;
		margin-left: 0;
	}
}

