.contact {
	transform: translateY(-20px);
	animation: content-pop-up .5s forwards;
}

.contact-heading {
	display: block;
	margin: 0 40px 20px 40px;
}

.contact-types-cont .contact-direct, .contact-types-cont img {
	display: inline-block;
	vertical-align: top;
	margin-left: 10px;
}

.contact-types-cont img {
	width: 105px;
	height: auto;
	margin-left: 40px;
}

.contact-direct a, .contact-direct span {
	display: block;
	text-decoration: none;
}

.contact-direct a:visited {
	color: blue;
}

